.ld-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  width: 200px;
  height: 120px;
  border-radius: 100%;
  border-color: #9c5d8a;
  border-width: 2px;
  border-style: solid none none none;
  animation: ld-flap 5s cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
}

#ld-spinner-wrapper {
  width: 25px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  animation: ld-circle 3s cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
}

.dot {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: white;
  z-index: 5;
}

#loader_wrapper {
  position: absolute;
  height: 90%;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: start;
}

@keyframes ld-flap {
  0% {
    transform: translateY(80px);
    height: 40px;
  }
  50% {
    height: 40px;
  }
  100% {
    transform: translateY(200px);
  }
}

@keyframes ld-circle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(0deg) scale(0.7);
  }
}
